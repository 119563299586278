.carousel-control-next{
    /* right: -150px; */
}

.po_items_ho {
    column-width: 25em;
    -moz-column-width: 25em;
    -webkit-column-width: 27em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem;
}

.po_item {
    display: inline-flex;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 12px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    align-items: center;
    justify-content: center;
}

.po_item img {
    max-width: 100%;
    height: 300px;

}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.856) ;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover{
    transform: scale(1.4);
    transition-duration: 500ms;
}

.po_item .content a:hover {
    text-decoration: none;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-pagination-bullet{
    background: white;
  }
  